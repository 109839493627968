<template>
	<b-modal id="create-repaired-batch" :title="modalTitle" size="lg" :ok-title="modalOkBtn" ref="modal" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row>
					<b-col>
						<b-form-group label="Total:" label-cols-sm="1" label-class="font-weight-bold pt-0">
							{{ totalRepaired }}
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col lg="5" md="6" sm="5">
						<b-form-group label="Select Condition" label-for="Select Condition">
							<v-select id="select-condition" name="Select Condition" class="style-chooser" label="text"
								v-model="selCondition" :options="conditionOptions"
								:reduce="(condition) => condition.value">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a asset type
									</em>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col lg="5" md="6" sm="5" class="pl-0 d-flex align-items-center">
						<b-button class="add-conditions" variant="primary" @click="onAddCondition">Add</b-button>
					</b-col>
				</b-row>
				<div v-if="isNonNull(classifications)">
					<b-row class="mt-1">
						<b-col sm="12">
							<b-row>
								<b-col sm="2">
									<b-form-group>
										<span class="lb-header">Condition</span>
									</b-form-group>
								</b-col>

								<b-col sm="4">
									<b-form-group>
										<span class="lb-header">Quantity</span>
									</b-form-group>
								</b-col>

								<b-col sm="3">
									<b-form-group>
										<span class="lb-header">Actions</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>

					<b-row>
						<b-col sm="12">
							<b-row v-for="(classification, key) in classifications" :key="key">
								<b-col sm="2">
									<b-form-group>
										<span class="lb-condition">{{ classification.name }}</span>
									</b-form-group>
								</b-col>

								<b-col sm="4">
									<b-form-group>
										<div class="input-group">
											<div class="input-group-prepend">
												<button class="btn btn-outline-secondary" type="button"
													@click="onIncrement(key, -1)">-</button>
											</div>
											<b-form-input id="quantity" name="Quantity" type="number"
												v-model="classifications[key].repaired" @input="onChangeValue(key)"
												class="text-center" />
											<div class="input-group-append">
												<button class="btn btn-outline-secondary" type="button"
													@click="onIncrement(key, 1)">+</button>
											</div>
										</div>
										<span class="lb-remaining">Remaining:
											{{ classification.quantity - classification.repaired }}
										</span>
									</b-form-group>
								</b-col>

								<b-col sm="3">
									<b-form-group>
										<b-button size="sm" v-b-tooltip.hover.top="'Remove condition'" variant="danger"
											@click.stop="onRemoveCondition(key)" class="mr-1 mt-1">
											<em class="fa fa-trash"></em>
										</b-button>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-row class="mb-3">
						<b-col sm="8">
							<span class="lb-nconditions">No conditions added yet.</span>
						</b-col>
					</b-row>
				</div>

				<b-row>
					<b-col sm="6">
						<b-form-group label="Date Time:" label-for="DateTime">
							<date-range-picker name="DateTime" :min-date="minDate" :max-date="maxDate"
								:timePicker="true" :timePicker24Hour="false" opens="center" :single-date-picker="true"
								v-model="dateRepaired" applyLabel="Apply" cancelLabel="Cancel"
								:style="{ width: '100%' }">
								<div slot="input">
									{{ formattedDate }}
								</div>
							</date-range-picker>
							<span v-show="errors.has('DateTime')" class="help-block">{{ errors.first('DateTime')
								}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Repaired By:" label-for="Repaired By">
							<v-select name="Repaired By" class="style-chooser" label="text" :options="userOptions"
								:reduce="(user) => user.value" v-model="selUser" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a user
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Repaired By')" class="help-block">
								{{ errors.first('Repaired By') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col sm="6">
						<b-form-group label="Supporting Document"
							description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
							<b-form-file id="supporting-docs" placeholder="Choose image" ref="supporting-docs"
								accept="image/png" @change="onSelectDocument($event)">
								<template slot="file-name" slot-scope="{ names }">
									<b-badge variant="dark">{{ names[0] }}</b-badge>
									<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
										+ {{ names.length - 1 }} More files
									</b-badge>
								</template>
							</b-form-file>
						</b-form-group>
						<!-- Preview -->
						<div class="document-file" v-if="isNonNull(selDocument)">
							<i @click="onShowImage(selDocument.url)">{{ selDocument.name }}</i>
							<em @click="onRemoveDocument()" class="fa fa-times-circle"></em>
						</div>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Remarks:" label-for="Remarks" description>
							<b-form-textarea name="Remarks" type="text" v-model="form.repaired.remarks" maxlength="200"
								v-validate="getValidationParam(true, remarksRegex)" :rows="3" placeholder="Remarks" />
							<span v-show="errors.has('Remarks')" class="help-block">
								{{ errors.first('Remarks') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { FileUtil } from '@/utils/fileUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// Others
import config from '@/config/env-constants';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'create-repaired-batch',
	components: {
		Loading,
		DateRangePicker
	},
	data() {
		return {
			form: { ...MaintenanceUtil.getBatchDefaultObj() },
			selCondition: null,
			selUser: { ...config.userDefaultValue },
			selDocument: null,
			classifications: {},
			totalRepaired: 0,

			conditionOptions: [],
			userOptions: [],
			allUsersObj: this.$store.getters.users,

			// Modal
			modalTitle: '',
			modalOkBtn: '',

			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		remarksRegex() {
			return config.remarksRegex;
		},

		dateRepaired: {
			get() {
				return this.form.repaired.dateRepaired;
			},

			set(value) {
				this.form.repaired.dateRepaired = moment(value.startDate).valueOf();
			},
		},

		formattedDate() {
			return moment(this.dateRepaired).format('MMMM D YYYY hh:mm A');
		},

		minDate() {
			const timestamp = DateUtil.getCurrentTimestamp();
			const adjustedTimestamp = DateUtil.getAdustedDateInDays(new Date(timestamp), 90);
			return new Date(adjustedTimestamp);
		},

		maxDate() {
			const timestamp = DateUtil.getCurrentTimestamp();
			return new Date(timestamp);
		},

	},
	mounted() {
		EventBus.$on('onedit-repaired-batch', async (value) => {
			this.onReset();
			if (!_.isEmpty(value)) {
				this.onEditData(value);
			}
		});

		EventBus.$on('oncreate-repaired-batch', (value) => {
			this.onReset();
			if (!_.isEmpty(value)) {
				this.onNewData(value);
			}
		});
	},
	methods: {
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			try {
				let isValid = await this.$validator.validateAll();
				if (!isValid) {
					this.$toaster.warning('Please address the field/s with invalid input');
					this.isLoading = false;
					return;
				}

				this.parseBatch();

				if (this.isValidBatch()) {
					this.$toaster.success(`Batch Repair number "${this.form.batchNo}" was created successfully.`);
					EventBus.$emit('onclose-repaired-batch', this.form);
					this.$refs.modal.hide();
				}

			} catch (error) {
				this.$toaster.error(`Error adding a new batch repair. Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		parseBatch() {

			this.form.classifications = [];
			_.forEach(this.classifications, classification => {
				this.form.classifications.push({
					condition: classification.name,
					quantity: classification.repaired,
					good: 0,
					rejected: 0
				});
			});

			this.form.repaired.document = this.selDocument;
			this.form.repaired.repairedBy = this.selUser.id;

			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			this.form.dateCreated = DateUtil.getCurrentTimestamp();
			this.form.createdBy = this.loggedUser.id;
		},

		isValidBatch() {

			if (this.form.classifications.length === 0) {
				this.$toaster.warning(`No classified repair conditions.`);
				return false;
			}

			const classificationsArr = Object.values(this.classifications);
			if (classificationsArr.some((item) => {
				return item.repaired > item.quantity || item.repaired <= 0;
			})) {
				this.$toaster.warning(`Repair counts must be greater than zero and less than or equal to the remaining count.`);
				return false;
			}

			if (_.isEmpty(this.form.repaired.document)) {
				this.$toaster.warning(`Repaired batch has no supporting document.`);
				return false;
			}

			return true;
		},

		isNonNull(obj) {
			return obj && !_.isEmpty(obj);
		},

		onEditData(value) {

			this.modalTitle = 'Edit Batch';
			this.modalOkBtn = 'Save';

			this.form = value.repairBatch;

			this.userOptions = DropDownItemsUtil.retrieveActiveUsersByCompany(
				this.allUsersObj, this.loggedUserCompany.id
			);

			// Find selected user
			const selectedUser = this.userOptions.find(
				option => option.value.id === this.form.repaired.repairedBy
			);
			if (selectedUser) {
				this.selUser = selectedUser.value;
			}

			for (const item of this.form.classifications) {
				this.$set(this.classifications, item.condition, {
					name: item.condition,
					quantity: value.repairConditions[item.condition].quantity,
					repaired: item.quantity
				});
			}

			this.onUpdateTotalRepaired();
			this.selDocument = this.form.repaired.document;
		},

		onNewData(value) {

			this.modalTitle = 'Create Batch';
			this.modalOkBtn = 'Create';

			this.form.batchNo = value.batchNo;
			this.form.maintenanceId = value.maintenanceId;

			const repairConditions = value.repairConditions;
			this.conditionOptions = [{ value: null, text: ' - Please select - ' }];
			_.forEach(repairConditions, condition => {
				this.conditionOptions.push({
					text: condition.name,
					value: {
						name: condition.name,
						quantity: condition.quantity,
						repaired: 0,
					}
				});
			});

		},

		onSelectDocument(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid license in PNG or JPEG format.'
				);
				vm.selDocument = {};
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"The repaired photo width and height shouldn't be greater than 1000 pixels."
					);
				} else {
					vm.selDocument = {
						name: `${vm.form.maintenanceId}_${vm.form.batchNo}_repaired_docs.jpg`,
						url: url,
						file: file,
						fbStoragePath: 'images/batches',
						isNew: true,
					};
				}
			};
			image.src = url;
		},

		onRemoveDocument() {
			this.selDocument = {};
		},

		onAddCondition() {
			if (this.selCondition) {
				const condition = JSON.parse(JSON.stringify(this.selCondition));
				this.$set(this.classifications, condition.name, condition);
				this.onUpdateTotalRepaired();
				this.selCondition = null;
			} else {
				this.$toaster.warning("No selected asset condition. Please try again.");
			}
		},

		onRemoveCondition(id) {
			this.$delete(this.classifications, id);
			this.onUpdateTotalRepaired();
		},

		onIncrement(id, value) {
			const prev = parseInt(this.classifications[id].repaired);
			const newv = Math.max(0, prev + value);
			this.$set(this.classifications[id], 'repaired', newv);
			this.onChangeValue(id);
		},

		onChangeValue(id) {
			const repaired = this.classifications[id].repaired;
			this.$set(this.classifications[id], 'repaired', parseInt(repaired));
			this.onUpdateTotalRepaired();
		},

		onUpdateTotalRepaired() {
			this.totalRepaired = 0;
			_.forEach(this.classifications, classification => {
				this.totalRepaired += classification.repaired;
			});
		},

		onShowImage(url) {
			let fileName = FileUtil.getUrlFileName(url);
			EventBus.$emit('onSelectImageView', {
				url: url,
				name: fileName
			});
			this.$bvModal.show('image-view-dialog');
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...MaintenanceUtil.getBatchDefaultObj() },
			this.form.repaired.dateRepaired = DateUtil.getCurrentTimestamp();

			this.selCondition = null;
			this.selUser = { ...config.userDefaultValue };
			this.selDocument = null;
			this.classifications = {};
			this.totalRepaired = 0;

			this.userOptions = DropDownItemsUtil.retrieveActiveUsersByCompany(
				this.allUsersObj, this.loggedUserCompany.id
			);
		},
	},
	beforeDestroy() {
		EventBus.$off('oncreate-repaired-batch');
		EventBus.$off('onedit-repaired-batch');
		EventBus.$off('onclose-repaired-batch');
	},
};
</script>

<style scoped>
.add-conditions {
	margin-top: 0.809em;
}

.lb-header {
	font-weight: bold;
}

.lb-condition {
	color: #008036;
	font-weight: bold;
}

.lb-remaining {
	color: #008036;
	font-style: italic;
	font-size: 0.9em;
	margin-left: 2.5em;
}

.lb-nconditions {
	color: #008036;
	font-style: italic;
}


.document-file {
	font-weight: bold;
	margin-top: -0.5em;
	margin-bottom: 0.5em;
	cursor: pointer;
}

.document-file i {
	color: #008036;
	margin-right: 0.5em;
}
</style>
